<template>
  <div class="section-content-wrapper auto-height">
    <LoadingState
      v-if="checkingOut"
      key="checkingOutState"
      :message="$translate('checkoutMessage')"
    />
    <SuccessTextSectionRenderer
      v-else
      @checkout="checkout"
    />
  </div>
</template>
<script setup lang="ts">
import SuccessTextSectionRenderer from '@/components/dynamic-form/renderers/section/SuccessTextSectionRenderer.vue';
import type { IVisitApi } from '../interfaces/api/i-visit-api';
import { useVisitStore } from '../store/visit';
import { onMounted, onUnmounted, ref } from 'vue';
import { RouteName } from '../router/route-name';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useSnackbar } from '@/composables/use-snackbar';
import LoadingState from '@/components/LoadingState.vue';
import { VisitApi } from '@/api/visit-api';

const visitApi: IVisitApi = new VisitApi();
const checkingOut = ref(false);
const router = useRouter();
const visitStore = useVisitStore();
const  { t } = useI18n();
const { showSnackbar } = useSnackbar();
const intervValId = ref<number | null>();

const checkout = async () => {
  try {
    checkingOut.value = true;
    await visitStore.checkout();
    router.replace({ name: RouteName.CheckedOut });
  } catch (error) {
    showSnackbar(t((error as Error).message));
    checkingOut.value = false;
  }
};

const verifyVisit = async () => {
  try {
    const visitVerification = await visitApi.verifyPendingVisit(visitStore.pendingVisitToken as string);
    if (visitVerification === false) {
      visitStore.clearPendingVisit();
      await router.replace({ name: RouteName.CheckedOut });
    }
  } catch (error) {
    await router.replace({ name: RouteName.CheckedOut });
  }
};

onMounted(async () => {
  await verifyVisit();
  intervValId.value = window.setInterval(() => {
    void verifyVisit();
  }, 8000);
});

onUnmounted(() => {
  if (intervValId.value) {
    window.clearInterval(intervValId.value);
  }
});

</script>
<style lang="scss" scoped>
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
</style>

